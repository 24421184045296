.f-image-edit {
  cursor: pointer;
}
.f-image-edit .f-image-edit__image {
  margin: auto;
}
.f-image-edit .f-image-edit__bottom {
  display: flex;
}
.f-image-edit .f-image-edit__bottom .f-image-edit__caption {
  display: flex;
  margin: 10px auto 0 0;
}
.f-image-edit .f-image-edit__bottom .f-image-edit__button {
  display: flex;
  margin: 10px 0 0 5px;
}
.f-image-edit .f-image-edit__file {
  display: none;
}
