@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.v-color-field__menu {
    .v-color-field__menu__buttons {
        display: flex;
        background-color: white;
    }
}

.v-color-field {
    .v-input__append-inner {
        margin: auto!important;
        .v-color-field__icon {
            margin: auto!important;
            font-size: 32px!important;
        }
    }
}
