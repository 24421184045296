@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.app-table {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-shadow: none !important;

  .app-table-content {
    display: flex;
    flex-direction: column;
    flex: fit-content;
    overflow: hidden;
  }

  .page-selector {
    width: 250px !important;
  }

  .v-data-table {
    height: 100%;
  }

  .v-data-table__wrapper {
    height: 100%;
    overflow-y: auto !important;
  }


  th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .app-table-pagination {
    margin-top: auto;
  }

  .app-table__cell {
    cursor: pointer;
  }

  .app-table__actions {
    display: flex;
    justify-content: flex-end;
  }

  .v-btn {
    width: 24px !important;
    height: 24px !important;
  }
}
