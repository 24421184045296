.app-tree-details {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-tree-details button.v-treeview-node__toggle + button.v-treeview-node__checkbox {
  display: none !important;
}
.app-tree-details .v-image-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 2px;
}
.app-tree-details .v-image-container img {
  display: flex;
  margin: auto;
  max-width: 27px;
  max-height: 27px;
}
.app-tree-details .v-treeview--dense .v-treeview-node__root {
  min-height: 32px;
}
.app-tree-details .app-tree-details__header {
  display: flex;
  height: 48px;
}
.app-tree-details .app-tree-details__header .v-toolbar {
  width: 100%;
}
.app-tree-details .app-tree-details__header .v-toolbar .v-btn--search {
  max-width: 350px;
}
.app-tree-details .app-tree-details__header .v-toolbar .v-input__slot {
  margin-bottom: 0!important;
  min-height: 36px!important;
}
.app-tree-details .app-tree-details__main {
  display: flex;
  flex-direction: row;
  height: calc(100% - 52px);
}
.app-tree-details .app-tree-details__main .app-tree-details__tree {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 360px;
  flex-shrink: 0;
}
.app-tree-details .app-tree-details__main .app-tree-details__tree .app-tree-details__tree-inner {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1 auto;
  min-height: 0;
}
.app-tree-details .app-tree-details__main .app-tree-details__tree .app-tree-details__tree-inner .v-treeview {
  width: 100%;
}
.app-tree-details .app-tree-details__main .app-tree-details__tree .app-tree-details__tree-inner .v-treeview .v-treeview-node__label {
  cursor: pointer;
}
.app-tree-details .app-tree-details__main .app-tree-details__content {
  display: flex;
  width: calc(100% - 360px);
  flex-direction: column;
  height: 100%;
}
.app-tree-details .app-tree-details__main .app-tree-details__content .app-tree-details__content-inner {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1 auto;
  min-height: 0;
  padding: 8px;
}
