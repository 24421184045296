@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; @import 'variables';

.dense.v-chip {
    margin: 0;
    height: 24px!important;
}
.dense.v-chip .v-chip__content {
    height: 24px;
}

.v-text-field__details {
    /*display: none!important;*/
}
.v-input {
    /*margin: 4px!important;*/
}

.w-100 {
    width: 100%!important;
}

.h-100 {
    height: 100%!important;
}
