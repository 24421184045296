.app-table {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-shadow: none !important;
}
.app-table .app-table-content {
  display: flex;
  flex-direction: column;
  flex: fit-content;
  overflow: hidden;
}
.app-table .page-selector {
  width: 250px !important;
}
.app-table .v-data-table {
  height: 100%;
}
.app-table .v-data-table__wrapper {
  height: 100%;
  overflow-y: auto !important;
}
.app-table th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.app-table .app-table-pagination {
  margin-top: auto;
}
.app-table .app-table__cell {
  cursor: pointer;
}
.app-table .app-table__actions {
  display: flex;
  justify-content: flex-end;
}
.app-table .v-btn {
  width: 24px !important;
  height: 24px !important;
}
