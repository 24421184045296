@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.v-image-field {
  display: flex;

  width: 40px;
  height: 40px;

  .v-file-input {
    width: 100%;
    height: 100%;
    .v-input__control {
      width: 100%;
      height: 100%;
      .v-input__slot {
        cursor: pointer;
        padding: 0!important;
        width: 100%;
        height: 100%;
        .v-text-field__slot {
          padding: 0!important;
        }
        fieldset {
          z-index: 1000;
        }
      }
    }
  }

  .v-image-field__container {
    display: flex;
    width: 100%;
    height: 100%;
    .v-image-field__img {
      display: flex;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
