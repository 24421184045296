.panel-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.panel-top {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 1em 0 1em 1em;
}
.panel-title {
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.panel-close {
  border: solid 1px #ccc;
  border-top-right-radius: 0;
  border-right: none;
}
.panel-content {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
}
.panel-content-body {
  flex: auto;
  overflow-y: auto;
  padding: 0 1em;
}
.panel-content-header {
  padding: 1em;
}
.panel-content-footer {
  margin-top: auto;
  padding: 1em;
}
.panel-wrapper .v-navigation-drawer:not(.v-navigation-drawer--right):not(.v-navigation-drawer--left):not(.v-navigation-drawer--bottom).v-navigation-drawer--open {
  transform: translateY(0) !important;
}
.panel-wrapper .v-navigation-drawer:not(.v-navigation-drawer--right):not(.v-navigation-drawer--left):not(.v-navigation-drawer--bottom).v-navigation-drawer--close {
  transform: translateY(-100%) !important;
}
.panel-wrapper .v-navigation-drawer.v-navigation-drawer--bottom {
  top: auto !important;
  bottom: 0 !important;
}
.panel-wrapper .v-navigation-drawer.v-navigation-drawer--bottom.v-navigation-drawer--open {
  transform: translateY(0) !important;
}
.panel-wrapper .v-navigation-drawer.v-navigation-drawer--bottom.v-navigation-drawer--close {
  transform: translateY(100%) !important;
}
.panel-wrapper .v-navigation-drawer__content {
  overflow-y: hidden !important;
}
