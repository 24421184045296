.eva-table1 {
  height: 100%;
  display: flex!important;
  flex-direction: column;
  padding: 8px;
}
.eva-table1 .eva-table__header {
  display: flex;
  align-items: center;
}
.eva-table1 th {
  white-space: nowrap!important;
}
.eva-table1 .app-table__content {
  height: calc(100% - 100px);
}
.eva-table1 .app-table__table {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.eva-table1 .app-table__pagination {
  height: 48px;
}
.eva-table1 .v-toolbar1 {
  flex-grow: 0;
}
.eva-table1 .v-toolbar1 .v-btn--search {
  max-width: 350px;
  margin-left: 24px!important;
}
.eva-table1 .v-toolbar1 .v-input__slot {
  margin-bottom: 0!important;
}
.eva-table1 .v-toolbar1 .v-toolbar__content {
  padding-right: 0!important;
}
.eva-table1 .app-table__cell {
  cursor: pointer;
}
.eva-table1 .app-table__actions {
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
}
.eva-table1 .app-table__actions .v-btn {
  margin-left: 8px;
}
.eva-table1 .app-table__actions .v-btn.v-btn--fab {
  width: 32px !important;
  height: 32px !important;
}
.eva-table1 .app-table__actions .v-btn.v-btn--fab .v-icon {
  font-size: 16px!important;
}
.eva-table1 .v-card__subtitle,
.eva-table1 .v-card__text,
.eva-table1 .v-card__title {
  padding: 0!important;
}
.eva-table1 .app-table__filter {
  display: flex;
  flex-direction: row;
}
.eva-table1 .app-table__filter .v-input {
  width: 0;
  min-width: 150px;
}
