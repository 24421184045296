.v-image-field {
  display: flex;
  width: 40px;
  height: 40px;
}
.v-image-field .v-file-input {
  width: 100%;
  height: 100%;
}
.v-image-field .v-file-input .v-input__control {
  width: 100%;
  height: 100%;
}
.v-image-field .v-file-input .v-input__control .v-input__slot {
  cursor: pointer;
  padding: 0!important;
  width: 100%;
  height: 100%;
}
.v-image-field .v-file-input .v-input__control .v-input__slot .v-text-field__slot {
  padding: 0!important;
}
.v-image-field .v-file-input .v-input__control .v-input__slot fieldset {
  z-index: 1000;
}
.v-image-field .v-image-field__container {
  display: flex;
  width: 100%;
  height: 100%;
}
.v-image-field .v-image-field__container .v-image-field__img {
  display: flex;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
