@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-table1 {
  height: 100%;
  display: flex!important;
  flex-direction: column;
  padding: 8px;

  .eva-table__header {
    display: flex;
    align-items: center;
  }

  th {
    white-space: nowrap!important;
  }

  .app-table__content {
    height: calc(100% - 100px);
  }

  .app-table__table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .app-table__pagination {
    height: 48px;
  }

  .v-toolbar1 {
    flex-grow: 0;
    .v-btn--search {
      max-width : 350px;
      margin-left: 24px!important;
    }
    .v-input__slot {
      margin-bottom: 0!important;
    }
    .v-toolbar__content {
      padding-right: 0!important;
    }
  }

  .app-table__cell {
    cursor: pointer;
  }
  .app-table__actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
    .v-btn {
      margin-left: 8px;
      &.v-btn--fab {
        width: 32px !important;
        height: 32px !important;
        .v-icon {
          font-size: 16px!important;
        }
      }
    }
  }

  .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 0!important;
  }
  .app-table__filter {
    display: flex;
    flex-direction: row;
    .v-input {
      width: 0;
      min-width: 150px;
    }
  }
}
