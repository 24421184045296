@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.app-tree {
  height: 100%;
  display: flex;
  flex-direction: column;

  .v-image-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2px;
    img {
      display: flex;
      margin: auto;
      max-width: 27px;
      max-height: 27px;
    }
  }

  .v-treeview--dense {
    .v-treeview-node__root {
      min-height: 32px;
    }
  }

  .app-tree__header {
    display: flex;
    height: 48px;
    .v-toolbar {
      width: 100%;
      .v-btn--search {
        max-width : 350px;
      }
      .v-input__slot {
        margin-bottom: 0!important;
        min-height: 36px!important;
      }
    }
  }
  .app-tree__main {
    display: flex;
    flex-direction: row;
    height: calc(100% - 52px);
    .app-tree__tree {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 360px;
      flex-shrink: 0;
      .app-tree__tree-inner {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
        min-height: 0;
        .v-treeview {
          width: 100%;
          .v-treeview-node__label {
            cursor: pointer;
          }
        }
      }
    }
    .app-tree__content {
      display: flex;
      width: calc(100% - 360px);
      flex-direction: column;
      height: 100%;
      .app-tree__content-inner {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
        min-height: 0;
        padding: 8px;
      }
    }
  }
}
