.v-color-field__menu .v-color-field__menu__buttons {
  display: flex;
  background-color: white;
}
.v-color-field .v-input__append-inner {
  margin: auto!important;
}
.v-color-field .v-input__append-inner .v-color-field__icon {
  margin: auto!important;
  font-size: 32px!important;
}
