@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.app-dialog {
    &.app-dialog--full_height {
        height: 100%
    }
    .app-dialog__card {
        display: flex;
        flex-direction: column;
        height: 100%
    }
}
